.section-features {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    margin: 3rem 0;
}

.section-features h3 {
    margin-top: 1.2rem;
    font-size: 2rem;
    text-transform: none;
}

.features-container {
    width: 90vw;
}

@media screen and (min-width: 800px) {

    .features-container div:nth-child(odd) {
        flex-direction: row
    }

    .features-container div:nth-child(even) {
        flex-direction: row-reverse;
    }
}
