.hero-container {
    align-items: center;
    padding: 1rem;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    height: 100%;
    left: 0;
    top: 25px;
    width: 90%;
}

.hero-title {
    text-align: left;
}

.hero-title h1 {
    color: #ffffff;
    line-height: 1.2;
}

.hero-desc p {
    color: #ffffff;
}

.hero-action {
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
}

.hero-action a {
    margin-bottom: 20px;
    border: 2px solid #28aaff;
}

.hero-img img {
    width: 80%;
    height: auto;
}

@media screen and (min-width:768px) {
    
    .hero-container {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr;
        grid-template-areas: "elements image";
        
    }
    
    .hero-elements {
        grid-area: elements;
    }

    .hero-img {
        grid-area: image;
    }

        .hero-desc {
            text-align: left;
        }
    
}