.nav-bar {
    align-items: center;
    display: flex;
    justify-content: center;
    margin: 2rem auto;
}

.nav-center {
    width: 90vw;
    max-width: 1120px;
}

.nav-center a {
    font-size: 1.3rem;
}

.nav-center a:visited {
    color: #0f172a;
}

.nav-center a img {
    width: 30px;
}

.nav-header {
    display: flex;
    border-bottom: solid 1px gray;
    justify-content: space-evenly;
    align-items: baseline;
    width: auto;
    margin-bottom: 0.5rem;
}

.nav-header .ayc-logo {
    width: 250px;
}

.nav-link {
    border-top: solid 1px gray;
    color: #0f172a;
    display: block;
    letter-spacing: 0.1rem;
    margin-top: 2px;
    padding: 1rem 0;
    text-align: center;
    text-decoration: none;
    text-transform: capitalize;
    transition: all 0.3s ease;
}

.nav-link:hover {
    color: #645cff;
}


.nav-btn {
    display: flex;
    background-color: transparent;
}

#menu-navBar {
    display: none;
}

@media screen and (min-width: 800px) {
    .nav-btn {
        display: none;
    }
    .nav-bar {
        height: 6rem;
    }
    
    .nav-center {
        display: flex;
        align-items: center;
    }

    .nav-header {
        padding-bottom: 0.5rem;
        margin-right: 2rem;
      
        height: auto;
        
    }

    #menu-navBar {
        display: flex;
    }

    .nav-link {
        border: 0;
        margin-right: 1rem;
    }

    .link-wa {
        display: none;
    }
}