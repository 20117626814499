.section-hero {
    background-size: auto;
    background-repeat: repeat;
    background: url('/images/diagonal-lines.svg');
    margin: 0 auto;
    min-height: calc(90vh - (6rem + 4rem));
    display: flex;
    width: 100vw;
}

.hero {
    width: 100vw;
    opacity: 0.9;
    background-color: var(--hero-blue);
    height: auto;
    position: relative;
    display: flex;
    justify-content: center;
}

@media screen and (min-width: 800px) {

    .nav-links {
        align-items: center;
        flex-direction: row;
        height: auto !important;
        width: 100%;
        justify-content: flex-end;
    }

}