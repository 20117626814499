.nav-links {
    display: flex;
    flex-direction: column;
    height: 0;
    overflow: hidden;
    transition: 0.3s ease-in-out all;
}


.nav-links .whats-logo {
    width: 25px;
}

.hide-menu {
    display: none;
}

.show-links {
    height: auto;
}

@media screen and (min-width: 800px) {

    .nav-links {
        align-items: center;
        flex-direction: row;
        height: auto !important;
        width: 100%;
        justify-content: flex-end;
    }

}
.nav-bar {
    align-items: center;
    display: flex;
    justify-content: center;
    margin: 2rem auto;
}

.nav-center {
    width: 90vw;
    max-width: 1120px;
}

.nav-center a {
    font-size: 1.3rem;
}

.nav-center a:visited {
    color: #0f172a;
}

.nav-center a img {
    width: 30px;
}

.nav-header {
    display: flex;
    border-bottom: solid 1px gray;
    justify-content: space-evenly;
    align-items: baseline;
    width: auto;
    margin-bottom: 0.5rem;
}

.nav-header .ayc-logo {
    width: 250px;
}

.nav-link {
    border-top: solid 1px gray;
    color: #0f172a;
    display: block;
    letter-spacing: 0.1rem;
    margin-top: 2px;
    padding: 1rem 0;
    text-align: center;
    text-decoration: none;
    text-transform: capitalize;
    transition: all 0.3s ease;
}

.nav-link:hover {
    color: #645cff;
}


.nav-btn {
    display: flex;
    background-color: transparent;
}

#menu-navBar {
    display: none;
}

@media screen and (min-width: 800px) {
    .nav-btn {
        display: none;
    }
    .nav-bar {
        height: 6rem;
    }
    
    .nav-center {
        display: flex;
        align-items: center;
    }

    .nav-header {
        padding-bottom: 0.5rem;
        margin-right: 2rem;
      
        height: auto;
        
    }

    #menu-navBar {
        display: flex;
    }

    .nav-link {
        border: 0;
        margin-right: 1rem;
    }

    .link-wa {
        display: none;
    }
}
.over-lay {
    position: fixed;
    left: 100vw;
    top: 0;
    width: 100vw;
    height: 100vh;
    z-index: 100;
    background: aliceblue;
    opacity: 0.95;
    transition: 0.4s ease-in-out all;
}

.ol-show {
    left: 0;
}

.ol-nav-links {
    width: 90vw;
    position: absolute;
    left: 50%;
    -webkit-transform: translate(-50%, 0);
            transform: translate(-50%, 0);
}


@media screen and (min-width: 800px) {

    .over-lay {
        left: 100vw;
    }

}
.hero-container {
    align-items: center;
    padding: 1rem;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    height: 100%;
    left: 0;
    top: 25px;
    width: 90%;
}

.hero-title {
    text-align: left;
}

.hero-title h1 {
    color: #ffffff;
    line-height: 1.2;
}

.hero-desc p {
    color: #ffffff;
}

.hero-action {
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
}

.hero-action a {
    margin-bottom: 20px;
    border: 2px solid #28aaff;
}

.hero-img img {
    width: 80%;
    height: auto;
}

@media screen and (min-width:768px) {
    
    .hero-container {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr;
        grid-template-areas: "elements image";
        
    }
    
    .hero-elements {
        grid-area: elements;
    }

    .hero-img {
        grid-area: image;
    }

        .hero-desc {
            text-align: left;
        }
    
}
.section-hero {
    background-size: auto;
    background-repeat: repeat;
    background: url('/images/diagonal-lines.svg');
    margin: 0 auto;
    min-height: calc(90vh - (6rem + 4rem));
    display: flex;
    width: 100vw;
}

.hero {
    width: 100vw;
    opacity: 0.9;
    background-color: var(--hero-blue);
    height: auto;
    position: relative;
    display: flex;
    justify-content: center;
}

@media screen and (min-width: 800px) {

    .nav-links {
        align-items: center;
        flex-direction: row;
        height: auto !important;
        width: 100%;
        justify-content: flex-end;
    }

}
.feat-container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}

    .feat-image {
        height: auto;
        margin: 1.5rem 0;
        width: 90vw;
    }

    .feat-desc {
        width: 90vw;
    }


@media screen and (min-width: 800px) {

    .feat-container {
        display: flex;
    }    

        .feat-image {
            width: 40vw;
        }

        .feat-desc {
            width: 40vw;
        }
 
}
.section-features {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    margin: 3rem 0;
}

.section-features h3 {
    margin-top: 1.2rem;
    font-size: 2rem;
    text-transform: none;
}

.features-container {
    width: 90vw;
}

@media screen and (min-width: 800px) {

    .features-container div:nth-child(odd) {
        flex-direction: row
    }

    .features-container div:nth-child(even) {
        flex-direction: row-reverse;
    }
}

.section-banner {
    margin: 2rem auto;
    background-color: #ececec;
    width: 90vw;
    height: 50vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
    text-align: center;
    padding: 4rem 1rem;
}

.banner-title {
    font-weight: bolder;
    line-height: 1.625;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
}

.banner-legend {
    color: #536171;
    line-height: 1.625;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
}

.banner-bg-blue {
    background-color: var(--hero-blue);
}

.banner-color-white {
    color: #fff;
}

.banner-p {
    padding: 0 2rem;
}
.page-footer {
    border-top: 1px solid #e1e4e9;
    padding-bottom: 1.5em;
    padding-top: 1.5em;
}

.page-footer p {
    margin-bottom: 0;
}

.page-footer .footer-container {
    
    display: flex;
    flex-direction: column;
    align-items: center;    

}

.footer-container .footer-copy {
    display: flex;
    flex-direction: column;
    align-items: center;    
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.section-about {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.section-about p {
    width: 90vw;
}

.about-title {
    font-weight: bolder;
    line-height: 1.625;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
}

