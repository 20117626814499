.feat-container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}

    .feat-image {
        height: auto;
        margin: 1.5rem 0;
        width: 90vw;
    }

    .feat-desc {
        width: 90vw;
    }


@media screen and (min-width: 800px) {

    .feat-container {
        display: flex;
    }    

        .feat-image {
            width: 40vw;
        }

        .feat-desc {
            width: 40vw;
        }
 
}