.page-footer {
    border-top: 1px solid #e1e4e9;
    padding-bottom: 1.5em;
    padding-top: 1.5em;
}

.page-footer p {
    margin-bottom: 0;
}

.page-footer .footer-container {
    
    display: flex;
    flex-direction: column;
    align-items: center;    

}

.footer-container .footer-copy {
    display: flex;
    flex-direction: column;
    align-items: center;    
}